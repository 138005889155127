<template>
  <b-row>
    <b-col md="3">
      <ProductCategorySort @handlerSort="handlerSort" />
    </b-col>
    <b-col md="9">
      <div>
        <CustomTable
          :items="items"
          :fields="fields"
          @handlerSort="handlerSort"
          @handlerDelete="handlerDelete"
        />
      </div>
      <!-- <div v-else>
        <no-data />
      </div> -->
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ProductCategorySort from './ProductCategorySort.vue'

export default {
  components: {
    BRow,
    BCol,
    ProductCategorySort,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      fields: [
        { key: 'avatar', label: 'Avatar', sortable: false },
        { key: 'name', label: 'Category name', sortable: true },
        { key: 'name', label: 'Number of products', sortable: true },
        { key: 'name', label: "Parent's category name", sortable: true },
        { key: 'position', label: 'Position', sortable: true },
        { key: 'created_at', label: 'Created at', sortable: true },
        { key: 'is_active', label: 'Status', sortable: false },
        { key: 'more', label: 'Tool', sortable: false },
        { key: 'sort', label: 'Reorder', sortable: false },
      ],
      items: [],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    handlerSort() {
      this.loadList()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/categories?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.perPage = res.count_page
          this.currentPage = res.current_page
          this.totalRows = res.items.length
          this.items = res.items.map(x => {
            if (x.parent_id !== 0) {
              const temp = res.items.find(y => x.parent_id === y.id_root)

              if (temp && temp.name !== undefined) {
                // eslint-disable-next-line no-param-reassign
                x.parent_name = temp.name
              } else {
                // eslint-disable-next-line no-param-reassign
                x.parent_name = 'None'
              }
            } else {
              // eslint-disable-next-line no-param-reassign
              x.parent_name = 'None'
            }
            return x
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/category/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Category has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>
